<template>
    <div class="order-table-container px-0 mx-0">
        <b-table
            responsive
            fixed
            no-local-sorting
            id="order-table"
            class="table-list"
            :items="orders"
            :fields="fields"
            :sort-by="camelCase(queryParams.sortBy)"
            :sort-desc="queryParams.sortOrder === 'desc'"
            v-on="$listeners"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #head(orderDate)="data">
                <div class="right-light-border">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template #head(createdBy)="data">
                <div class="content">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">{{ t('LastFirst') }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(orderNumber)="data">
                <div class="d-flex">
                    <div @click="setSelectedTotal(data.item.total)">
                        <custom-router-link
                            :to="{
                                name:
                                    data.item.orderTypeName === 'Direct Bulk Orders'
                                        ? 'BulkOrderDetails'
                                        : 'OrderDetails',
                                params:
                                    data.item.orderTypeName === 'Direct Bulk Orders'
                                        ? {bulkOrderId: data.item.orderHeaderId.toString()}
                                        : {orderId: data.item.orderHeaderId.toString()},
                            }"
                            class="record-link text-primary mb-1"
                        >
                            {{ getOrderNumberDisplay(data.item) }}
                        </custom-router-link>
                    </div>
                </div>
            </template>
            <template #cell(status)="{item}">
                {{ orderStatus(item) }}
            </template>
        </b-table>
    </div>
</template>

<script>
import {camelCase} from 'lodash';
import {mapState, mapActions} from 'vuex';
import date from '@/filters/date';
import {getReservationOrderStatus} from '@/utilities/formatters';

export default {
    components: {},
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            colWidths: {
                //based on a 1536px width resolution screen
                orderDate: '125px',
                createdBy: '250px',
                orderTypeName: '285px',
                orderNumber: '225px',
                poNumber: '262px',
                lineItems: '108px',
                total: '108px',
                status: '194px',
            },
        };
    },
    computed: {
        ...mapState({
            orders: (state) => state.orders.list,
        }),
        hasPricingPermission() {
            return this.checkPermissions({
                [this.PERMISSIONS.PRICING]: [this.PERMISSIONS_VALUES.ENABLED],
            });
        },
        fields() {
            return [
                {
                    key: 'orderDate',
                    label: this.t('ordered'),
                    sortable: true,
                    tdClass: 'text-caption text-gray-dark',
                    formatter: (value) => date(value),
                },
                {key: 'createdBy', label: this.t('createdBy')},
                {
                    key: 'orderTypeName',
                    label: this.t('orderType'),
                    sortable: true,
                    formatter: (value) => value || this.t('notAvailable'),
                },
                {
                    key: 'orderNumber',
                    label: this.t('ordernumber'),
                    sortable: true,
                },
                {key: 'poNumber', label: this.t('poNumber'), sortable: true},
                {key: 'lineItems', label: this.t('lineItemCount')},
                ...[
                    this.hasPricingPermission
                        ? {
                              key: 'total',
                              label: this.t('total'),
                              tdClass: 'no-wrap',
                              formatter: (value) => this.$options.filters.toCurrency(value),
                          }
                        : {},
                ],
                {
                    key: 'status',
                    label: this.t('statusNormalCase'),
                    sortable: true,
                },
            ];
        },
    },
    methods: {
        camelCase,
        getReservationOrderStatus,
        ...mapActions('orders', ['updateSelectedTotal']),
        setSelectedTotal(total) {
            this.updateSelectedTotal(total);
        },
        getOrderNumberDisplay(item) {
            if (item.isPendingBulkOrder) {
                return this.t('pending');
            }

            return item.orderNumber;
        },
        orderStatus(item) {
            const status = item.orderSubStatus || item.orderStatus;
            return this.getReservationOrderStatus(status);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

::v-deep .light-border-right {
    border-right: 1px solid $gray-light;
}
</style>
